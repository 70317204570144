.show {
  transform: scale(1) !important;
}

.popup-back {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 100;

  height: 100vh;
  width: 100vw;

  background-color: rgba(0,0,0,0);

  display: flex;
  justify-content: center;
  align-items: center;

  transform: scale(0);
  transition: all 0.5s;

  .popup-wrapper {
    height: 80%;
    width: 80%;
    max-width: 700px;

    z-index: 101;

    padding: 5vmin;

    // background: var(--white);
    background: rgba(120, 120, 120, 0.5);
    backdrop-filter: blur(12px);
    border-radius: 1rem;
    box-shadow: var(--black);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    // overflow: scroll;

    *{
      background: none;
      color: white;

      animation-name: fadein;
      animation-duration: 1s;
      animation-timing-function: ease;
      animation-iteration-count: 1;
    }

    input, textarea{
      box-sizing: border-box;
      width: 100%;
      color: white;
      transition: all 0.3s;
      border: 3px solid #DDDDDD;
      padding: 1em;
      // margin: 2px;
      border-radius: 1em;
      resize:none;
      transition: height 0s;
      &[type="submit"] {
        border: 1px solid rgba(255, 0, 0, 0.5);
        color: rgba(255, 0, 0, 0.5);

        &:focus{
          background: rgba(255, 0, 0, 0.5);
          outline:0;
        }
        &:hover{
          color: rgba(255, 0, 0, 1);
          border: 1px solid rgba(255, 0, 0, 1);
        }
      }
      &:focus{
        background: rgba(120, 120, 120, 0.5);
        outline:0;
      }
      &:hover{
        border: 3px solid #FFFFFF;
      }
    }

    a.link {
      color: blue;
      cursor: pointer;
    }
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
