.plans-wrapper {
    .plans-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;        
        width: 100%;
        height: 400px;
    }
    .plans-contents {
        display: flex;
        justify-content: center;
        align-items: center;  
        flex-wrap: wrap;      

        .card {
            width: 250px;
            height: 500px;
            margin: 20px;
            padding: 20px;
            // border: 2px solid black;
            position: relative;
            border-radius: 10px;
            box-shadow: 10px 10px 50px var(--gray);
            .head {
                display: flex;
                justify-content: center;
                align-items: center;   
                flex-direction: column;       
                height: 40%;
                h3 {
                    text-align: center;
                }
                h4 {
                    text-align: center;
                }
            }
            .body {
                display: flex;
                justify-content: center;
                align-items: center;   
                flex-direction: column; 
                justify-content:space-around;
                height: 60%;
                ul {
                    margin: 20px;
                    list-style: none;
                    li{
                        margin: 8px 0;
                    }
                }
                .annotation {
                    color: var(--unrecognizable-white)
                }    
            }
        }
        .card1 {
            background-color: #869AC2; 
        }
        .card2{
            background-color: #72C2A0; 
        }
        .card3{
            background-color: #C2978F; 
        }

    }
    .plans-sub {
        display: inline-block;
        width: 100%;
        text-align: center;
        margin: 50px 0 0 0;
    }
}