/* for popup ================================================ */
.headerPopup-wrapper {
  width : 100%;
  height: 100%;

  display         : flex;
  justify-content : center;
  align-items     : center;
  flex-direction  : column;

  overflow: scroll;

  // z-index: 100;

  div {
    width: 100%;
    a {
      width: 100%;
      padding: 10px;
      margin: 10px 0;
      display         : block;
      color           : white;
      text-decoration : none;
      font-size: 20px;
      transition: all 0.3s;

      border: 1px solid #AAAAAA;

      &:hover, &:focus {
        background: rgba(120, 120, 120, 0.5);
        border: 1px solid #FFFFFF;
      }
    }
  }


}
